import React from "react";
import { Agreement, BodyContainer, MainTitle, SubTitle } from "./ForChat";

const ForUsage: React.FC = () => {
  return (
    <BodyContainer>
      <Agreement>
        <MainTitle>이용약관</MainTitle>
        <br />
        <SubTitle>제1조 목적</SubTitle>
        <br />
        <p>
          이 약관은 아임페어드 앱(이하 "앱")을 운영하는 아임페어드 회사(이하 "회사")와 앱을 이용하는 이용자(이하
          "이용자") 간의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.
        </p>
        <br />
        <SubTitle>제2조 용어의 정의</SubTitle>
        <br />
        <p>
          ① "앱"이란 아임페어드 회사가 개발하여 제공하는 돌봄서비스, 등하원 도우미, 교육, 상담, 치료 등의 서비스를
          제공하는 모바일 애플리케이션을 말합니다.
        </p>
        <br />
        <p>② "이용자"란 앱을 이용하는 개인 또는 법인을 말합니다.</p>
        <br />
        <p>③ "회사"란 앱을 운영하는 아임페어드 회사를 말합니다.</p>
        <br />
        <SubTitle>제3조 이용약관의 효력</SubTitle>
        <br />
        <p>① 이 약관은 앱을 설치하고 사용하는 모든 이용자에게 적용됩니다.</p>
        <br />
        <p>② 회사는 이 약관을 변경할 수 있으며, 변경된 약관은 회사의 홈페이지에 공지함으로써 효력을 발생합니다.</p>
        <br />
        <p>③ 이용자는 변경된 약관에 동의하지 않을 경우, 서비스 이용을 중단할 수 있습니다.</p>
        <br />
        <SubTitle>제4조 이용자의 의무</SubTitle>
        <br />
        <p>① 이용자는 다음 각 호에 해당하는 행위를 하여서는 안 됩니다.</p>
        <br />
        <p>다른 이용자의 권리나 명예, 신용을 침해하는 행위</p>
        <p>허위 또는 과장된 정보를 유포하는 행위</p>
        <p>회사의 운영을 방해하거나 저해하는 행위</p>
        <p>회사의 지적재산권을 침해하는 행위</p>
        <p>기타 관계 법령에 위반되는 행위</p>
        <p>② 이용자는 앱을 이용하여 다음 각 호의 내용을 게시하여서는 안 됩니다.</p>
        <br />
        <p>음란물이나 폭력적인 내용을 포함하는 게시물</p>
        <p>정치적, 종교적, 사회적 논란을 야기하는 게시물</p>
        <p>광고성 게시물</p>
        <p>기타 회사가 부적절하다고 판단하는 게시물</p>
        <SubTitle>제5조 회사의 권리 및 의무</SubTitle>
        <br />
        <p>① 회사는 다음 각 호에 해당하는 경우 이용자의 앱 이용을 제한하거나 중지할 수 있습니다.</p>
        <br />
        <p>이용자가 제4조 각 호의 행위를 한 경우</p>
        <p>이용자가 앱을 이용하여 회사의 정상적인 운영을 방해하거나 저해하는 경우</p>
        <p>이용자가 앱을 이용하여 회사의 지적재산권을 침해하는 경우</p>
        <p>기타 회사가 부적절하다고 판단하는 경우</p>
        <p>② 회사는 다음 각 호에 해당하는 경우 이용자에게 사전 통지 없이 앱을 폐지할 수 있습니다.</p>
        <br />
        <p>회사의 경영상 또는 기술상의 필요에 의한 경우</p>
        <p>이용자의 이용률이 저조한 경우</p>
        <p>기타 회사가 필요하다고 인정하는 경우</p>
        <SubTitle>제6조 손해배상</SubTitle>
        <br />
        <p>
          ① 이용자가 이 약관을 위반하여 회사 또는 제3자에게 손해를 입힌 경우, 이용자는 그 손해를 배상할 책임을 지습니다.
        </p>
        <br />
        <p>② 회사는 이용자의 귀책사유로 인한 앱의 중지 또는 이용제한으로 발생한 손해에 대해 책임을 지지 않습니다.</p>
        <br />
        <SubTitle>제7조 분쟁의 해결</SubTitle>
        <br />
        <p>① 이 약관에 명시되지 않은 사항에 대해서는 관계 법령 또는 상관례에 따릅니다.</p>
        <br />
        <p>
          ② 이 약관의 해석 및 적용에 관하여 회사와 이용자 간에 이견이 있는 경우, 회사와 이용자는 이를 신속하고 원만하게
          해결하기 위해 노력합니다.
        </p>
        <br />
        <p>③ 이 약관에 따른 분쟁으로 소송이 제기되는 경우, 회사의 소재지 법원을 관할 법원으로 합니다.</p>
        <br />
        <SubTitle>부칙</SubTitle>
        <br />
        <p>이 약관은 2023년 11월 23일부터 시행됩니다.</p>
      </Agreement>
    </BodyContainer>
  );
};

export default ForUsage;
