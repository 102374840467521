import React from "react";
import { Route, Routes } from "react-router-dom";
import Main from "./Main";
import Layout from "./Layout";
import ForChat from "./agreement/ForChat";
import ForUsage from "./agreement/ForUsage";
import ForPrivateInfo from "./agreement/ForPrivateInfo";

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Main />} />
        <Route path="/teacher" element={<Main />} />
        <Route path="/agreement/chat" element={<ForChat />} />
        <Route path="/agreement/usage" element={<ForUsage />} />
        <Route path="/agreement/privacy" element={<ForPrivateInfo />} />
      </Route>
    </Routes>
  );
};

export default App;
