import React from "react";
import styled from "styled-components";
import childIntro from "~/assets/images/child_intro.png";
import teacherIntro from "~/assets/images/teacher_intro.png";
import mobileChildIntro from "~/assets/images/mobile_child.png";
import mobileTeacherIntro from "~/assets/images/mobile_teacher.png";
import { useMediaQuery } from "react-responsive";

const Main: React.FC = () => {
  const nowPath = window.location.pathname;
  const isTablet = useMediaQuery({
    query: "(max-width: 767px)",
  });
  const AppStoreLink = "https://apps.apple.com/kr/app/아임페어드/id6472881697";
  const TeacherAppStoreLink = "https://apps.apple.com/kr/app/아임페어드-선생님용/id6472881924";
  const PlayStoreLink = "https://play.google.com/store/apps/details?id=com.btheegg.impaired";
  const TeacherPlayStoreLink = "https://play.google.com/store/apps/details?id=com.btheegg.impaired.teacher";

  const handleButtonClick = (): void => {
    const userAgent: string = navigator.userAgent || navigator.vendor;
    // iOS 장치 감지 (MSStream 사용하지 않음)
    if (/iPad|iPhone|iPod/.test(userAgent) && !/CriOS/.test(userAgent)) {
      window.open(AppStoreLink, "_blank");
    } else if (/android/i.test(userAgent)) {
      window.open(PlayStoreLink, "_blank");
    } else {
      // PC나 다른 플랫폼일 경우
      window.open(PlayStoreLink, "_blank");
    }
  };
  const handleTeacherButtonClick = (): void => {
    const userAgent: string = navigator.userAgent || navigator.vendor;
    // iOS 장치 감지 (MSStream 사용하지 않음)
    if (/iPad|iPhone|iPod/.test(userAgent) && !/CriOS/.test(userAgent)) {
      window.open(TeacherAppStoreLink, "_blank");
    } else if (/android/i.test(userAgent)) {
      window.open(TeacherPlayStoreLink, "_blank");
    } else {
      // PC나 다른 플랫폼일 경우
      window.open(TeacherPlayStoreLink, "_blank");
    }
  };
  return (
    <MainBody>
      {nowPath === "/" && (
        <MainImg
          src={isTablet ? mobileChildIntro : childIntro}
          alt="학생용 앱 소개 페이지 이미지"
          onClick={handleButtonClick}
        />
      )}
      {nowPath === "/teacher" && (
        <MainImg
          src={isTablet ? mobileTeacherIntro : teacherIntro}
          alt="선생님용 앱 소개 페이지 이미지"
          onClick={handleTeacherButtonClick}
        />
      )}
    </MainBody>
  );
};

export default Main;
const MainBody = styled.div`
  justify-content: center;
  /* position: absolute; */
  padding-bottom: 341px;

  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1023px) {
    padding-top: 6%;
    padding-bottom: 217px;
  }
  @media screen and (max-width: 450px) {
    padding-top: 12%;
    padding-bottom: 217px;
  }
`;
const MainImg = styled.img`
  width: 100%;
  cursor: pointer;
  z-index: -1;
  @media screen and (max-width: 1023px) {
    margin: -60px 0 0 0;
  }
`;
