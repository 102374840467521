import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import logoChild from "../../assets/images/child_logo.svg";
import logoTeacher from "../../assets/images/teacher_logo.svg";
import { useMediaQuery } from "react-responsive";

const Layout: React.FC = () => {
  const nowPath = window.location.pathname;
  const navigate = useNavigate();
  const isTablet = useMediaQuery({
    query: "(max-width: 1023px)",
  });
  return (
    <ProjectBody>
      <HeaderBody>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <LogoImg src={nowPath === "/teacher" ? logoTeacher : logoChild} alt="logo" onClick={() => navigate("/")} />

          <SpaceBtwn>
            <span
              style={{
                color: nowPath === "/" ? "#7145AF" : "rgba(25, 25, 25, 0.40)",
                fontWeight: nowPath === "/" ? "700" : "500",
              }}
              onClick={() => navigate("/")}
            >
              아임페어드 소개
            </span>
            <span
              style={{
                color: nowPath === "/teacher" ? "#FF9406" : "rgba(25, 25, 25, 0.40)",
                fontWeight: nowPath === "/teacher" ? "700" : "500",
              }}
              onClick={() => navigate("/teacher")}
            >
              아임페어드 선생님
            </span>
          </SpaceBtwn>
        </div>
      </HeaderBody>

      <Outlet />
      <Footer>
        <FooterTopPart>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <FooterLogo src={nowPath === "/teacher" ? logoTeacher : logoChild} alt="logo" />
            <Height16 />
            <span>아임페어드 | 대표이사 : 김여진</span>
            <Empty style={{ height: "8px" }} />
            <span>사업자등록번호 : 428-03-03017</span>
            <Empty style={{ height: "8px" }} />
            <span>주소 : 서울특별시 강남구 논현로 151길 41, 3층 3998호(신사동, 상운빌딩)</span>
            <Height16 />
            <div>© 아임페어드. All Rights Reserved</div>
            {isTablet && (
              <TabletBottomPart>
                <span onClick={() => navigate("/agreement/usage")}>이용약관</span>
                <span onClick={() => navigate("/agreement/privacy")}>개인정보처리방침</span>
                <span onClick={() => navigate("/agreement/chat")}>채팅 이용약관</span>
              </TabletBottomPart>
            )}
          </div>
        </FooterTopPart>
        {!isTablet && (
          <FooterBottomPart>
            <span onClick={() => navigate("/agreement/usage")}>이용약관</span>
            <span onClick={() => navigate("/agreement/privacy")}>개인정보처리방침</span>
            <span onClick={() => navigate("/agreement/chat")}>채팅 이용약관</span>
          </FooterBottomPart>
        )}
      </Footer>
    </ProjectBody>
  );
};

export default Layout;
const TabletBottomPart = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  padding-top: 26px;
  box-sizing: border-box;
  gap: 16px;
  span {
    color: #191919 !important;
    font-size: 12px;
    font-weight: 500;
  }
`;
const Height16 = styled.div`
  height: 16px;
  @media screen and (max-width: 1023px) {
    height: 12px;
  }
`;

const FooterBottomPart = styled.div`
  gap: 16px;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  padding: 32px 162px;
  span {
    color: #191919;
    font-size: 16px;
    font-weight: 500;
    font-family: "pretendard";
    cursor: pointer;
  }
`;
const Empty = styled.div``;
const FooterTopPart = styled.div`
  color: #191919;
  font-size: 18px;
  font-family: "Pretendard";
  box-sizing: border-box;
  width: 100%;
  height: 264px;
  border-bottom: 1px solid #dbdbdb;
  padding: 56px 162px 32px 162px;
  span {
    font-size: 16px;
    color: #767676;
    font-weight: 400;
    font-family: "pretendard";
    @media screen and (max-width: 1023px) {
      font-size: 12px;
    }
    @media screen and (max-width: 767px) {
      font-size: 10px;
    }
  }
  @media screen and (max-width: 1023px) {
    border-bottom: none;
    font-size: 14px;
    height: 218px;
    padding: 21px 42px 0 42px;
  }
  @media screen and (max-width: 767px) {
    font-size: 12px;
    height: 218px;
    padding: 21px 42px 0 42px;
  }
`;

const FooterLogo = styled.img`
  width: 153px;
  height: 100%;
  @media screen and (max-width: 1023px) {
    width: 102px;
    margin-bottom: 26px;
  }
  @media screen and (max-width: 767px) {
    width: 58px;
    margin-bottom: 26px;
  }
`;
const ProjectBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Footer = styled.div`
  width: 100%;
  height: 343px;
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #dbdbdb;
  @media screen and (max-width: 1023px) {
    height: 218px;
  }
`;
const HeaderBody = styled.header`
  display: flex;
  /* position: absolute; */
  z-index: 100;
  background-color: #ffffff;
  height: 100px;
  box-sizing: border-box;
  width: 100%;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.08);
  justify-content: space-between;
  margin: 0 auto;
  padding: 40px 162px;

  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    box-sizing: border-box;
    height: 60px;
    width: 100%;
    padding: 20px 18px;
  }
`;
const LogoImg = styled.img`
  width: 122px;

  @media screen and (max-width: 1023px) {
    width: 82px;
  }
`;

const SpaceBtwn = styled.div`
  align-items: end;
  display: flex;
  justify-content: space-between;
  gap: 32px;

  @media screen and (max-width: 1023px) {
    gap: 18px;
  }
  span {
    cursor: pointer;
    font-size: 20;
    font-weight: 500;

    @media screen and (max-width: 1023px) {
      font-size: 11px;
    }
  }
`;
