import React from "react";
import { Agreement, BodyContainer, MainTitle, SubTitle } from "./ForChat";

const ForPrivateInfo: React.FC = () => {
  return (
    <BodyContainer>
      <Agreement>
        <MainTitle>개인정보처리방침</MainTitle>
        <br />
        <SubTitle>제1조 목적</SubTitle>
        <br />
        <p>
          이 개인정보처리방침은 아임페어드 앱(이하 "앱")을 운영하는 아임페어드(이하 "회사")회사가 앱을 통해 수집하는
          이용자의 개인정보를 보호하고, 이를 안전하게 처리함으로써 이용자의 권익을 보호함을 목적으로 합니다.
        </p>
        <br />
        <SubTitle>제2조 용어의 정의</SubTitle>
        <br />
        <p>
          ① "개인정보"란 살아 있는 개인에 관한 정보로서 당해 정보에 포함된 성명, 주민등록번호, 주소, 연락처, 영상정보,
          기타 정보에 의하여 당해 개인을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른
          정보와 쉽게 결합하여 식별할 수 있는 정보를 포함한다)를 말합니다.
        </p>
        <br />
        <p>② "개인정보처리자"란 개인정보를 처리할 수 있는 권한을 가진 자를 말합니다.</p>
        <br />
        <p>③ "이용자"란 앱을 이용하는 개인 또는 법인을 말합니다.</p>
        <br />
        <SubTitle>제3조 개인정보의 수집 및 이용 목적</SubTitle>
        <br />
        <p>회사는 다음과 같은 목적을 위하여 이용자의 개인정보를 수집하고 이용합니다.</p>
        <br />
        <p>앱의 서비스 제공 및 향상</p>
        <p>이용자의 편의 제공</p>
        <p>이용자와의 원활한 의사소통</p>
        <p>회원 관리 및 마케팅 활동</p>
        <br />
        <SubTitle>제4조 개인정보의 수집 및 이용 방법</SubTitle>
        <br />
        <p>회사는 이용자의 개인정보를 다음과 같은 방법으로 수집합니다.</p>
        <br />
        <p>이용자가 앱을 설치하고 회원가입을 하는 경우, 이용자가 직접 제공하는 개인정보</p>
        <p>이용자가 앱을 이용하는 과정에서 발생하는 개인정보</p>
        <p>회사가 제휴한 제3자로부터 제공받는 개인정보</p>
        <p>회사는 이용자의 개인정보를 다음과 같은 목적을 위하여 이용합니다.</p>
        <br />
        <p>앱의 서비스 제공 및 향상</p>
        <p>
          회사는 이용자의 개인정보를 이용하여 앱을 안정적이고 효율적으로 운영하고, 이용자 맞춤형 서비스를 제공하기
          위하여 이용합니다.
        </p>
        <br />
        <p>이용자의 편의 제공</p>
        <p>
          회사는 이용자의 개인정보를 이용하여 이용자의 편의를 제공하기 위하여 이용합니다. 예를 들어, 이용자의 위치정보를
          이용하여 주변의 돌봄 선생님을 추천하거나, 이용자의 관심사를 반영하여 콘텐츠를 추천하는 등의 방식으로 이용자의
          편의를 제공합니다.
        </p>
        <br />
        <p>이용자와의 원활한 의사소통</p>
        <p>
          회사는 이용자의 개인정보를 이용하여 이용자와의 원활한 의사소통을 위하여 이용합니다. 예를 들어, 이용자의 문의에
          대한 답변이나, 이용자의 의견 수렴을 위하여 이용자의 개인정보를 이용합니다.
        </p>
        <br />
        <p>회원 관리 및 마케팅 활동</p>
        <p>
          회사는 이용자의 개인정보를 이용하여 회원 관리 및 마케팅 활동을 위하여 이용합니다. 예를 들어, 회원의 이용
          기록을 분석하여 서비스 개선을 위한 자료로 활용하거나, 회원에게 새로운 서비스나 이벤트 정보를 제공하기 위하여
          이용자의 개인정보를 이용합니다.
        </p>
        <br />
        <SubTitle>제5조 개인정보의 보유 및 이용 기간</SubTitle>
        <br />
        <p>회사는 이용자의 개인정보를 다음과 같이 보유하고 이용합니다.</p>
        <br />
        <p>회원 가입 및 관리</p>
        <p>
          회사는 회원 가입 시 수집한 개인정보를 회원탈퇴 시까지 보유합니다. 다만, 관련 법령에 따라 보존할 필요가 있는
          경우에는 해당 기간 동안 보관합니다.
        </p>
        <br />
        <p>앱 이용 관련</p>
        <p>
          회사는 이용자가 앱을 이용하는 과정에서 발생하는 개인정보를 앱의 운영 종료 시까지 보관합니다. 다만, 관련 법령에
          따라 보존할 필요가 있는 경우에는 해당 기간 동안 보관합니다.
        </p>
        <br />
        <p>마케팅 활동</p>
        <p>
          회사는 마케팅 활동을 위하여 수집한 개인정보를 마케팅 활동 종료 시까지 보관합니다. 다만, 관련 법령에 따라
          보존할 필요가 있는 경우에는 해당 기간 동안 보관합니다.
        </p>
        <br />
        <SubTitle>제6조 개인정보의 제3자 제공</SubTitle>
        <br />
        <p>회사는 이용자의 개인정보를 다음과 같은 경우 제3자에게 제공할 수 있습니다.</p>
        <br />
        <p>이용자가 사전에 동의한 경우</p>
        <p>회사는 이용자가 사전에 동의한 경우에 한하여 개인정보를 제3자에게 제공합니다.</p>
        <br />
        <p>법령의 규정에 의하여 제공하는 경우</p>
        <p>
          회사는 법령의 규정에 의하여 개인정보를 제3자에게 제공하는 경우에는 당해 법령에 따라 제3자에게 제공할 수
          있습니다.
        </p>
        <br />
        <p>법령의 규정에 의하여 제공하는 경우</p>
        <p>
          회사는 법령의 규정에 의하여 개인정보를 제3자에게 제공하는 경우에는 당해 법령에 따라 제3자에게 개인정보를
          제공할 수 있습니다. 예를 들어, 수사기관의 수사 협조 요청이나, 법원의 명령에 의하여 개인정보를 제공하는 경우
          등이 있습니다.
        </p>
        <br />
        <p>수탁자에 의한 처리</p>
        <p>
          회사는 업무의 효율적인 수행을 위하여 개인정보 처리 업무를 외부 업체에 위탁하여 처리할 수 있습니다. 이 경우,
          회사는 위탁계약 등을 통하여 개인정보가 안전하게 처리될 수 있도록 필요한 조치를 취하고 있습니다.
        </p>
        <br />
        <p>이용자 동의 없이 제3자에게 제공하는 경우</p>
        <p>
          회사는 이용자 동의 없이 개인정보를 제3자에게 제공하는 경우 다음과 같은 경우에 한하여 개인정보를 제공할 수
          있습니다.
        </p>
        <br />
        <p>가. 이용자가 자신의 개인정보를 타인에게 제공하도록 동의한 경우</p>
        <br />
        <p>나. 법령의 규정에 의하여 개인정보를 제공하는 경우</p>
        <br />
        <p>다. 공공기관이 업무의 수행을 위하여 법령에 따라 개인정보를 제공받는 경우</p>
        <br />
        <p>라. 개인정보를 제공받는 자가 제공받은 목적을 달성하지 못할 때까지 개인정보를 보유할 필요가 있는 경우</p>
        <br />
        <p>
          마. 개인정보를 제공받는 자가 개인정보를 제3자에게 제공하거나 공공기관에 제공하기 위하여 개인정보를 보유할
          필요가 있는 경우
        </p>
        <br />
        <SubTitle>제7조 개인정보의 열람, 정정, 삭제 및 처리정지</SubTitle>
        <br />
        <p>이용자는 언제든지 자신의 개인정보에 대한 열람, 정정, 삭제 및 처리정지를 요구할 수 있습니다.</p>
        <br />
        <p>회사는 이용자의 요구에 대하여 지체 없이 필요한 조치를 취할 것입니다.</p>
        <br />
        <SubTitle>제8조 개인정보의 안전성 확보 조치</SubTitle>
        <br />
        <p>회사는 이용자의 개인정보를 안전하게 처리하기 위하여 다음과 같은 조치를 취하고 있습니다.</p>
        <br />
        <p>개인정보의 관리</p>
        <p>
          회사는 이용자의 개인정보를 취급하는 자를 최소한으로 제한하고, 개인정보의 분실, 도난, 유출, 변조 등으로 인한
          피해를 예방하기 위하여 필요한 보안 조치를 취하고 있습니다.
        </p>
        <br />
        <p>개인정보의 보호조치</p>
        <p>회사는 개인정보의 안전한 처리를 위하여 내부 관리계획을 수립하고, 시행하고 있습니다.</p>
        <br />
        <p>개인정보의 관리책임자 및 담당자 지정</p>
        <p>
          회사는 개인정보의 관리책임자 및 담당자를 지정하고, 그들에게 개인정보의 보호 및 관리에 관한 사항을 교육하고
          있습니다.
        </p>
        <br />
        <SubTitle>제9조 개인정보 보호 관련 고충처리</SubTitle>
        <br />
        <p>
          이용자는 개인정보침해로 인한 피해를 구제 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원
          개인정보침해신고센터 등에 분쟁조정 또는 신고를 할 수 있습니다.
        </p>
        <br />
        <SubTitle>부칙</SubTitle>
        <br />
        <p>이 개인정보처리방침은 2023년 11월 23일부터 시행됩니다.</p>
        <br />
      </Agreement>
    </BodyContainer>
  );
};

export default ForPrivateInfo;
